<template>
	<div>
		<HeaderPhone topTitle="联系我们" style="position: fixed;"></HeaderPhone>
		<div style="height: 50px;"></div>
		<div class="banner"></div>
		<div class="container">
			<div style="padding:0 20px;">
				<div v-for="(item,index) in list" :key="index" style="background-color: #fff;padding:20px;">
					<p style="font-size: 22px;color: #26497E;font-weight: 600;margin-bottom: 30px;">
						{{item.title}}
					</p>
					<div class="vue-flex">
						<p style="margin: 0 10px 10px 0;">
							<el-image :src="require('../static/images/contact/mail.png')" style="width: 18px;height: 12px;"></el-image>
						</p>
						<p>
							电子邮箱：{{item.email}}
						</p>
					</div>
					<div class="vue-flex">
						<p style="margin: 0  10px 10px 0;">
							<el-image :src="require('../static/images/contact/L.png')" style="width: 17px;height: 18px;"></el-image>
						</p>
						<p>
							公司地址：{{item.address}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<FooterPhone></FooterPhone>
	</div>
</template>

<script>
import HeaderPhone from "@/components/headerPhone";
import FooterPhone from "@/components/footerPhone";
export default {
	data() {
		return {
			list: [
				{title: '网宇科创（广东总部）',email: 'Bp@wangyu.com',address: '广东省东莞市南城区黄金路1号天安数码城H1栋16-17楼'},
				{title: '网宇科创（福建公司）',email: 'Bp@wangyu.com',address: '福建省厦门市集美区软件园三期F10栋'},
			],
		}
	},
	components: {
		HeaderPhone,
		FooterPhone,
	},
	methods: {
		
	},
}
</script>

<style scoped>
.banner {
	height: 38vh;
	background: url("../static/imagesPhone/contact/Mask group.jpg") no-repeat;
	background-size: cover;
	background-position: center center;
}
.container {
	margin-top: 50px;
	height: 450px;
	background: url("../static/imagesPhone/contact/Mask groupxxx.jpg") no-repeat;
	background-size:100% 65%;
	background-position: center bottom;
}
</style>